 

app.directive('ngUpdate', [function () {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;
            var elementValue;
            var changevalue = false;
            elem.bind('blur', function(event) {
                if(elementValue != elem.val().trim() && changevalue){
                    scope.$eval(attrs.ngUpdate);
                }
            });
            elem.bind('change',function (event) {
                changevalue = true;
            })
            elem.bind('focus', function(event) {
                elementValue = elem.val();
                changevalue = false;
            });
        }
    };
}]);



app.directive('ngMaxlength', [function () {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;
            var elementValue;
            var changevalue = false;
            elem.bind('blur', function(event) {
                 if(elem.val().length!=attrs.ngMaxlength){
                     elem.focus();   
                 }
            });                          
        }
    };
}]);

  
app.directive('validFile',function(){
    return {
        require:'ngModel',
        link:function(scope,el,attrs,ctrl){
            ctrl.$setValidity('validFile', el.val() != '');
            //change event is fired when file is selected
            el.bind('change',function(){
                ctrl.$setValidity('validFile', el.val() != '');
                scope.$apply(function(){
                    ctrl.$setViewValue(el.val());
                    ctrl.$render();
                });
            });
        }
    }
})




app.directive('imageUpload', ['$parse','$timeout', function ($parse,$timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {  
            //$(element).AjaxFileUpload(); 
            var randomId='id'+Math.floor((Math.random() * 1000) + 1); 
			$timeout(function () {
				 $(element).hide().before('<img src="/images/default.jpg" class="mw-100 '+$(element).attr('class')+' '+randomId+'" />');				 
				  $('.'+randomId).click(function(){
						$(this).next().click();
					}) 
			}, 1000);
			                                      
            element.on('change', function (event) { 
				var filename=$(element).val().replace(/^.*[\\\/]/, '');										   				 				         
				var file = this.files[0];
                var reader = new FileReader();                            
                reader.onload = function (e) {                         
                    $('.'+randomId).attr('src', e.target.result);                
                }        
                reader.readAsDataURL(file);                              
            });                                                                    
        }
    };
}]);