

app.controller('AppController', ['$scope', '$rootScope', 's', '$location','$state',  '$compile', '$http', '$timeout','$localStorage', function ($scope, $rootScope, s, $location,$state,  $compile, $http,$timeout,$localStorage) {
     
     $rootScope.isLogin = false;
     if($localStorage.shardamandir){           
          $rootScope.isLogin = true; 
     } 
     
     $scope.submit= function ($event,action,data,params,method,loader,retry,callback) {
           
          var element=$($event.currentTarget);              
          if($event.currentTarget.tagName == 'FORM'){
               element = $('[type="submit"]',$event.currentTarget);               
          }
          var value = element.html();
          element.attr({disabled:true}).html('<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...');
          var url = action;
          if(params){
               url += jsonToQueryString(params);
          }
 
          s.callback(url,data,method,loader,retry,function (response) {                                													   
               let status = 'danger';
               if(response.code === 'retry'){
                    callback({action:'retry'});  
               }               
               else{                                                        
                    if(response.status=='success'){  
                         status = 'success';                       
                         if(callback){
                              callback(response);
                         }
                    }
                    element.attr({disabled:false}).html(value);                                                   
               }                              
               $rootScope.notify(status,response.msg);
          });
     }

     var timer;
     $scope.filterInput = function (value) {
          $timeout.cancel(timer);
          timer = $timeout(function () {
               $rootScope.filter = value;
          },500)
     }

     $scope.replace = function (master,chunk,field) {
          const obj = ($filter('filter')(master, field, true)[0]);
          const key = master.indexOf(obj);
          master[key] = chunk;
     }

     $scope.findby = function (master,field) {
         return $filter('filter')(master, field, true);
     }

    $scope.findandreplace = function (items,searchfield,replacewith) {
        items.forEach((element, index) => {
            if(element[searchfield] === replacewith[searchfield]) {
                items[index] = replacewith;
            }
        });
    }

     $scope.removeitem = function (master,item,callback) {
          if(confirm('Are you sure to delete?')) {
               const index = master.indexOf(item);
               master.splice(index, 1);
               return callback('deleted');
          }
          return callback('cancel');
     }
 
     $rootScope.notify = function (status,msg) {           
          $rootScope.alert = {'msg':msg,'status':status};
          $timeout(function () {
               $rootScope.alert.msg='';
          },7000);
     }

     $scope.print = function(){
          window.print();
     }

     $scope.jsontocsv = function(json,fields,filename){           
          JSONToCSVConvertor(json,fields,filename,true);
     }

     $scope.timestamptodate = function(timeStamp_value){
          if(!timeStamp_value){
               return '';
          }
          var date = new Date(timeStamp_value * 1000);
          dateString = date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear()+
          " "+date.getHours()+
          ":"+date.getMinutes()+
          ":"+date.getSeconds();
          return dateString;
     }

}]);
 
app.controller('loginController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {
     
     $scope.data = {mobile:'',otp:''}
     $scope.step = '1';

     if($localStorage.shardamandir){           
          $location.path('dashboard');   
     }      
 
       $scope.sendotp = function($event){     
          if($scope.counter == 0){                    
               $scope.submit($event,'sendotp',{mobile:$scope.data.mobile},'','POST',true,true,function(response){                                      
                    $scope.data.otp = response.otp;               
                    $scope.onTimeout(); 
                    $scope.step = '2';                                      
               });
          }
       }

       $scope.findstudent = function($event){
          $scope.submit($event,'getStudentByFind',{findby:$scope.data.mobile},'','POST',true,true,function(response){   
               if(response.status === 'success'){                                                   
                    $localStorage.shardamandir = {mobile:$scope.data.mobile};      
                    $rootScope.isLogin = true;         
                    $location.path('payfees');                                     
               }
          });
       }

          $scope.counter = 0;
          let mytimeout;
          $scope.onTimeout = function(){
               $scope.counter++;
               if($scope.counter >= 61){                    
                    $scope.stop();
               }
               else{
                    mytimeout = $timeout($scope.onTimeout,1000);
               }               
          }           
          $scope.stop = function(){
               $timeout.cancel(mytimeout);
               $scope.counter = 0;
          }

       $scope.verifymobile = function($event){                             
           if($scope.verifyotp == $scope.data.otp){
               $localStorage.shardamandir = {mobile:$scope.data.mobile};      
               $rootScope.isLogin = true;         
               $location.path('payfees');                               
           }
           else{
               $rootScope.notify('danger','Wrong OTP');
           }
       }
      
       $scope.otpInput={
          size:4,
          type:"text",
          onDone: function(value){                
               $scope.verifyotp = value;
          },
          onChange: function(value){
              
          }
      };     
}]);

app.controller('logoutController',['$scope','$rootScope','$location','$localStorage',function ($scope,$rootScope,$location,$localStorage) {            
     $localStorage.shardamandir = false;     
     $rootScope.isLogin = false;  
     $location.path('/');
}]);

app.controller('payfeesController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {           
     let mobile='';

     $scope.number = 12;
     $scope.getNumber = function(num) {
          return new Array(num);   
      }

     $scope.getStudentbyMobile = function () {      
          s.callback('getStudentByFind',{findby:mobile},'POST',true,false,function (response) {                                                       													   
               $scope.students = response.students;     
               
               $scope.students.map((student)=>{                     
                    if(!Boolean(student.outstanding > 0)){                         
                         student.outstanding = student.fees.newfee;
                    }                    
               }) 
          });                                                                 
     }
     if(!$localStorage.shardamandir){           
          $location.path('login');   
     } 
     else{
          mobile = $localStorage.shardamandir.mobile;
          $scope.getStudentbyMobile();     
     }     

     $scope.paynow = function(grno,amount){
         
          
          s.callback('getOrderId',{findby:grno,mobile:$localStorage.shardamandir.mobile,amount:amount},'POST',true,false,function (response) {    
                                             
               $scope.payto = response;     
              
               let options = {
                    "key": $scope.payto.fees.key_id, 
                    "amount": +amount * 100, 
                    "currency": "INR",
                    "name": $scope.payto.fees.institutename,
                    "description": $scope.payto.name,                     
                    "image": "http://shardamandirnadiad.org/images/sm-logo-color.png",                     
                    "callback_url": "https://shardamandirnadiad.org/apidocs/sardamandir/callback.php?order_id="+$scope.payto.orderid,
                    "prefill": {
                        "name": "",
                        "email": "",
                        "contact": $scope.payto.mobile 
                    }, 
                    "notes": { 
                        "grno": $scope.payto.grno,
                        "order_id": $scope.payto.orderid
                    },
                    "theme": {
                        "color": "#a61d37"
                    }
                };
                let rzp = new Razorpay(options);
                rzp.open();                          
          }); 

        
                 
     }
 

}]);


app.controller('transactionsController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {           
     let mobile='';
     $scope.getTransactionsbyMobile = function () {      
          s.callback('getTransactions',{findby:mobile},'POST',true,false,function (response) {                                                       													   
               $scope.transactions = response.transactions;                            
          });                                                  
     }
     if(!$localStorage.shardamandir){           
          $location.path('login');   
     } 
     else{
          mobile = $localStorage.shardamandir.mobile;
          $scope.getTransactionsbyMobile();     
     }     
}]);


app.controller('paymentstatusController',['$scope','$rootScope','$location','$sessionStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$sessionStorage,$timeout,$filter,s,$stateParams,$window) {
     let orderid = $stateParams.orderid;
     $scope.getPaymentstatus = function () {      
          s.callback('getTransactions',{findby:orderid},'POST',true,false,function (response) {                                                       													   
                $scope.transactions = response.transactions[0];
          });                                                        
     }      
     $scope.getPaymentstatus();   
}]); 

  
app.controller('indexController',['$scope','$rootScope','$location','$localStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$localStorage,$timeout,$filter,s,$stateParams,$window) {
     function init(){
          s.callback('init','','GET',true,false,function (response) {                                         													   
               $scope.standards = response.standard;   
          });  
     }
     $scope.getStudents = function(){          
          s.callback('getStudents',{class:$scope.standard},'POST',true,false,function (response) {                                         													   
               $scope.students = response.students;   
          });           
     }
     $('.carousel').carousel();    
     //init(); 
}]);

 
app.controller('contactusController',['$scope','$rootScope','$location','$sessionStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$sessionStorage,$timeout,$filter,s,$stateParams,$window) {
         
}]);

app.controller('eventsController',['$scope','$rootScope','$location','$sessionStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$sessionStorage,$timeout,$filter,s,$stateParams,$window) {
         
}]);

app.controller('newsController',['$scope','$rootScope','$location','$sessionStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$sessionStorage,$timeout,$filter,s,$stateParams,$window) {
         
}]);

app.controller('aboutusController',['$scope','$rootScope','$location','$sessionStorage','$timeout','$filter','s','$stateParams','$window',function ($scope,$rootScope,$location,$sessionStorage,$timeout,$filter,s,$stateParams,$window) {
         
}]);


function jsonToQueryString(json) {
     return '&' +
         Object.keys(json).map(function(key) {
              return encodeURIComponent(key) + '=' +
                  encodeURIComponent(json[key]);
         }).join('&');
}

function copy(mainObj) {
     let objCopy = {};
     let key;

     for (key in mainObj) {
          objCopy[key] = mainObj[key];
     }
     return objCopy;
}

function clone(obj){
     var copy;
     if (null == obj || "object" != typeof obj) return obj;
     if (obj instanceof Date) {
          copy = new Date();
          copy.setTime(obj.getTime());
          return copy;
     }
     if (obj instanceof Array) {
          copy = [];
          for (var i = 0, len = obj.length; i < len; i++) {
               copy[i] = clone(obj[i]);
          }
          return copy;
     }
     if (obj instanceof Object) {
          copy = {};
          for (var attr in obj) {
               if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
          }
          return copy;
     }
     throw new Error("Unable to copy obj! Its type isn't supported.");
}
 

function printDiv() {
     window.print();
 }


 

 