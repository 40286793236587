var code = 'en-IN';
app.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});
 
app.filter('date2', function($filter) {
    return function(date) {         
        return new $filter('date')(date, 'yyyy-MM-dd')
    };
});
 
 
/*

app.filter('month', function() {
    const options = {
        month: '2-digit'
    };
    return function(date) {
        return new Intl.DateTimeFormat(code,options).format(date);
    };
});

app.filter('year', function() {
    const options = {
        year: 'numeric'
    };
    return function(date) {
        return new Intl.DateTimeFormat(code,options).format(date);
    };
});

app.filter('monthyear', function() {
    const options = {
        year: 'numeric', month: '2-digit'
    };
    return function(date) {
        return new Intl.DateTimeFormat(code,options).format(date);
    };
});


app.filter('day', function() {
    const options = {
        day: '2-digit'
    };
    return function(date) {
        return new Intl.DateTimeFormat(code,options).format(date);
    };
});

app.filter('daymonth', function() {
    const options = {
        day: '2-digit', month: 'short'
    };
    return function(date) {
        return new Intl.DateTimeFormat(code,options).format(date);
    };
});


 app.filter('time', function() {
    const options = {
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        hour12: false
    };
    return function(time) {
        return new Intl.DateTimeFormat(code,options).format(time);
    };
}); */

 
app.filter('currency',function() {
    return function(number) {
        return new Intl.NumberFormat('en-IN',{style:'currency',currency:'INR',minimumFractionDigits: 2}).format(number);
    };
});

app.filter('range', function() {
    return function(input, total) {
      total = parseInt(total);
      for (var i=0; i<total; i++)
        input.push(i);
      return input;
    };
  });

app.filter('words', function() {
    function isInteger(x) {
          return x % 1 === 0;
      }
  
    
    return function(value) {
      if (value && isInteger(value))
        return  toWords(value);
      
      return value;
    };
  
  });
  
  
  var th = ['','thousand','million', 'billion','trillion'];
  var dg = ['zero','one','two','three','four', 'five','six','seven','eight','nine']; 
  var tn = ['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen', 'seventeen','eighteen','nineteen'];
  var tw = ['twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety']; 
  
  
  function toWords(s)
  {  
      s = s.toString(); 
      s = s.replace(/[\, ]/g,''); 
      if (s != parseFloat(s)) return 'not a number'; 
      var x = s.indexOf('.'); 
      if (x == -1) x = s.length; 
      if (x > 15) return 'too big'; 
      var n = s.split(''); 
      var str = ''; 
      var sk = 0; 
      for (var i=0; i < x; i++) 
      {
          if ((x-i)%3==2) 
          {
              if (n[i] == '1') 
              {
                  str += tn[Number(n[i+1])] + ' '; 
                  i++; 
                  sk=1;
              }
              else if (n[i]!=0) 
              {
                  str += tw[n[i]-2] + ' ';
                  sk=1;
              }
          }
          else if (n[i]!=0) 
          {
              str += dg[n[i]] +' '; 
              if ((x-i)%3==0) str += 'hundred ';
              sk=1;
          }
  
  
          if ((x-i)%3==1)
          {
              if (sk) str += th[(x-i-1)/3] + ' ';
              sk=0;
          }
      }
      if (x != s.length)
      {
          var y = s.length; 
          str += 'point '; 
          for (var i=x+1; i<y; i++) str += dg[n[i]] +' ';
      }
      return str.replace(/\s+/g,' ');
  }
  
  window.toWords = toWords;