
app.service('s', ['$rootScope', '$http','$timeout', function ($rootScope, $http,$timeout) {

    var s = this;
    var token=true;
  

    this.callback = function (action, data, method,loader=true,retry=false, callbackFunc) {		
        var url =   serverurl +'/?action='+   action;
	 
        if (data) {
            l(data, 'data');
            data = JSON.stringify(data);             
        }         
        $rootScope.loader = loader;
        $http({
            method: method,
            url: url,            
            dataType: 'text',
            data: data, 
            timeout: retry?12000:60000           
        }).then(function successCallback(response) {
            l(response, 'Callback : '+url);
            if (response.data.status == 'error') {                
                $rootScope.notify('danger',response.data.msg);                
            }
            $rootScope.loader = false;
            callbackFunc(response.data);
        }, function errorCallback(response) {
            l(response, 'Fail : '+url);            
            $rootScope.loader = false;            
            if(retry){
               callbackFunc({status:'error',code:'retry',msg:'Please  wait retry again...'}); 
            }
            else{
                callbackFunc({status:'error',msg:'Request timeout....'}); 
            }
        });
    };
 
}]);



function l(data,title) { 
    
    console.group(title);
    console.log(data);
    console.groupEnd();  
   
}

 