var app;
var serverurl;



import './scss/style.scss';

import 'bootstrap/dist/js/bootstrap.min';
import 'angular';
import '@uirouter/angularjs';
import 'ngstorage';
import 'ng-mask';
import 'otp-input-directive';


import './js/resources/jquery.appear';
 

 
import './js/resources/custom';


import './js/config';
import './js/services';
import './js/directive';
import './js/filter';
import './js/controller';




