module.exports
{

    app = angular.module('app', ['ui.router','ngStorage','ngMask','otpInputDirective']); //'angular-uuid','angucomplete-alt','ui.utils.masks'     
    serverurl='https://shardamandirnadiad.org/apidocs/sardamandir/index.php';
    //serverurl='http://localhost/apidocs/sardamandir/index.php';
 
   // console.log(IS_DEV); 
 
    app.run(['$rootScope', '$state', '$stateParams','$transitions','$localStorage','$timeout',function ( $rootScope,   $state,   $stateParams,$transitions,$localStorage,$timeout ) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            $transitions.onSuccess({}, function() {                  
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });
    }]);

    app.run(function ($rootScope, $templateCache) {         
        $rootScope.$on('$viewContentLoaded', function () {            
            $templateCache.removeAll();
        });
    });

    var mySwiper;
    app.run( function($log, $rootScope, $http, $templateCache){
        $rootScope.updateSwiper = function () {
            mySwiper.reInit();
        }
    });

    app.config(['$locationProvider', function ($locationProvider) {
        //$locationProvider.html5Mode({enabled: true});
        $locationProvider.hashPrefix('');
    }]);

    var date = new Date().getTime().toString();

    app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/index');
        $stateProvider
                       
        .state('index', {
            url: '/index',
            templateUrl: 'views/index.html?t='+date,
            controller: 'indexController',
            cache: false,
            data: {title: 'Index', theme: 'dark'}
        })  

        .state('contactus', {
            url: '/contactus',
            templateUrl: 'views/contactus.html?t='+date,
            controller: 'contactusController',
            cache: false,
            data: {title: 'Contact Us', theme: 'dark'}
        })  
        
        .state('login', {
            url: '/login',
            templateUrl: 'views/login.html?t='+date,
            controller: 'loginController',
            cache: false,
            data: {title: 'Login', theme: 'dark'}
        }) 
        .state('logout', {
            url: '/logout',
            templateUrl: 'views/logout.html?t='+date,
            controller: 'logoutController',
            cache: false,
            data: {title: 'Logout', theme: 'dark'}
        }) 

        .state('payfees', {
            url: '/payfees',
            templateUrl: 'views/payfees.html?t='+date,
            controller: 'payfeesController',
            cache: false,
            data: {title: 'Pay Fees', theme: 'dark'}
        }) 

        .state('transactions', {
            url: '/transactions',
            templateUrl: 'views/transactions.html?t='+date,
            controller: 'transactionsController',
            cache: false,
            data: {title: 'Transactions', theme: 'dark'}
        }) 

        .state('paymentstatus', {
            url: '/paymentstatus/:orderid',
            templateUrl: 'views/paymentstatus.html?t='+date,
            controller: 'paymentstatusController',
            cache: false,
            data: {title: 'Payment Status', theme: 'dark'}
        }) 

        .state('news', {
            url: '/news',
            templateUrl: 'views/news.html?t='+date,
            controller: 'newsController',
            cache: false,
            data: {title: 'News', theme: 'dark'}
        }) 

        .state('events', {
            url: '/events',
            templateUrl: 'views/events.html?t='+date,
            controller: 'eventsController',
            cache: false,
            data: {title: 'Events', theme: 'dark'}
        }) 

        .state('aboutus', {
            url: '/aboutus',
            templateUrl: 'views/aboutus.html?t='+date,
            controller: 'aboutusController',
            cache: false,
            data: {title: 'About Us', theme: 'dark'}
        }) 
 
      

    }]);

}







